import React from "react";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import BackgroundImage from "gatsby-background-image";
import { useStaticQuery, graphql } from "gatsby";
import GetInTouch from "../../components/get-in-touch";
import RelatedProducts from "../../components/related-products";

interface Feature {
    title: string;
    description: string;
    viewBox: string;
    icon: string;
}

const BusinessTravelManagementPage = () => {
    const { t } = useTranslation();

    const FEATURES: Feature[] = [
        {
            title: t("Packaging and Standalone Bookings"),
            description: t(
                "Booking is possible as package or by flight",
            ),
            viewBox: "0 0 750 800",
            icon:
                "M425.7 256c-16.9 0-32.8-9-41.4-23.4L320 126l-64.2 106.6c-8.7 14.5-24.6 23.5-41.5 23.5-4.5 0-9-.6-13.3-1.9L64 215v178c0 14.7 10 27.5 24.2 31l216.2 54.1c10.2 2.5 20.9 2.5 31 0L551.8 424c14.2-3.6 24.2-16.4 24.2-31V215l-137 39.1c-4.3 1.3-8.8 1.9-13.3 1.9zm212.6-112.2L586.8 41c-3.1-6.2-9.8-9.8-16.7-8.9L320 64l91.7 152.1c3.8 6.3 11.4 9.3 18.5 7.3l197.9-56.5c9.9-2.9 14.7-13.9 10.2-23.1zM53.2 41L1.7 143.8c-4.6 9.2.3 20.2 10.1 23l197.9 56.5c7.1 2 14.7-1 18.5-7.3L320 64 69.8 32.1c-6.9-.8-13.5 2.7-16.6 8.9z",
        },
        {
            title: t("Easy-to-Use Travel Booking Platform"),
            description: t(
                "Mobile responsive and multilingual",
            ),
            viewBox: "0 0 750 800",
            icon:
                "M255.03 261.65c6.25 6.25 16.38 6.25 22.63 0l11.31-11.31c6.25-6.25 6.25-16.38 0-22.63L253.25 192l35.71-35.72c6.25-6.25 6.25-16.38 0-22.63l-11.31-11.31c-6.25-6.25-16.38-6.25-22.63 0l-58.34 58.34c-6.25 6.25-6.25 16.38 0 22.63l58.35 58.34zm96.01-11.3l11.31 11.31c6.25 6.25 16.38 6.25 22.63 0l58.34-58.34c6.25-6.25 6.25-16.38 0-22.63l-58.34-58.34c-6.25-6.25-16.38-6.25-22.63 0l-11.31 11.31c-6.25 6.25-6.25 16.38 0 22.63L386.75 192l-35.71 35.72c-6.25 6.25-6.25 16.38 0 22.63zM624 416H381.54c-.74 19.81-14.71 32-32.74 32H288c-18.69 0-33.02-17.47-32.77-32H16c-8.8 0-16 7.2-16 16v16c0 35.2 28.8 64 64 64h512c35.2 0 64-28.8 64-64v-16c0-8.8-7.2-16-16-16zM576 48c0-26.4-21.6-48-48-48H112C85.6 0 64 21.6 64 48v336h512V48zm-64 272H128V64h384v256z",
        },
        {
            title: t("Industry Leading Integrated Providers"),
            description: t(
                "DEEP has interfaces to leading providers providing more",
            ),
            viewBox: "0 0 750 800",
            icon:
                "M384 320H256c-17.67 0-32 14.33-32 32v128c0 17.67 14.33 32 32 32h128c17.67 0 32-14.33 32-32V352c0-17.67-14.33-32-32-32zM192 32c0-17.67-14.33-32-32-32H32C14.33 0 0 14.33 0 32v128c0 17.67 14.33 32 32 32h95.72l73.16 128.04C211.98 300.98 232.4 288 256 288h.28L192 175.51V128h224V64H192V32zM608 0H480c-17.67 0-32 14.33-32 32v128c0 17.67 14.33 32 32 32h128c17.67 0 32-14.33 32-32V32c0-17.67-14.33-32-32-32z",
        },
        {
            title: t("Mobile Application"),
            description: t(
                "Native iOS and Android Mobile application with offline",
            ),
            viewBox: "-50 0 550 630",
            icon:
                "M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z",
        },
        {
            title: t("Travel Policy and Approval Flow"),
            description: t(
                "Create Trips with multiple travel components",
            ),
            viewBox: "0 0 550 600",
            icon:
                "M436 160c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h320c26.5 0 48-21.5 48-48v-48h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20zm-68 304H48V48h320v416zM208 256c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm-89.6 128h179.2c12.4 0 22.4-8.6 22.4-19.2v-19.2c0-31.8-30.1-57.6-67.2-57.6-10.8 0-18.7 8-44.8 8-26.9 0-33.4-8-44.8-8-37.1 0-67.2 25.8-67.2 57.6v19.2c0 10.6 10 19.2 22.4 19.2z",
        },
        {
            title: t("Back Office Intranet Application"),
            description: t(
                "Bookings can be easily searched",
            ),
            viewBox: "0 0 650 700",
            icon:
                "M192 208c0-17.67-14.33-32-32-32h-16c-35.35 0-64 28.65-64 64v48c0 35.35 28.65 64 64 64h16c17.67 0 32-14.33 32-32V208zm176 144c35.35 0 64-28.65 64-64v-48c0-35.35-28.65-64-64-64h-16c-17.67 0-32 14.33-32 32v112c0 17.67 14.33 32 32 32h16zM256 0C113.18 0 4.58 118.83 0 256v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16c0-114.69 93.31-208 208-208s208 93.31 208 208h-.12c.08 2.43.12 165.72.12 165.72 0 23.35-18.93 42.28-42.28 42.28H320c0-26.51-21.49-48-48-48h-32c-26.51 0-48 21.49-48 48s21.49 48 48 48h181.72c49.86 0 90.28-40.42 90.28-90.28V256C507.42 118.83 398.82 0 256 0z",
        },
        {
            title: t("Commercial & Financial Dashboards"),
            description: t(
                "Top management and revenue managers",
            ),
            viewBox: "0 0 650 700",
            icon:
                "M496 384H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM464 96H345.94c-21.38 0-32.09 25.85-16.97 40.97l32.4 32.4L288 242.75l-73.37-73.37c-12.5-12.5-32.76-12.5-45.25 0l-68.69 68.69c-6.25 6.25-6.25 16.38 0 22.63l22.62 22.62c6.25 6.25 16.38 6.25 22.63 0L192 237.25l73.37 73.37c12.5 12.5 32.76 12.5 45.25 0l96-96 32.4 32.4c15.12 15.12 40.97 4.41 40.97-16.97V112c.01-8.84-7.15-16-15.99-16z",
        },
        {
            title: t("Expense Management"),
            description: t(
                "Capture the receipt and upload photos via the mobile application",
            ),
            viewBox: "0 0 550 600",
            icon:
                "M358.4 3.2L320 48 265.6 3.2a15.9 15.9 0 0 0-19.2 0L192 48 137.6 3.2a15.9 15.9 0 0 0-19.2 0L64 48 25.6 3.2C15-4.7 0 2.8 0 16v480c0 13.2 15 20.7 25.6 12.8L64 464l54.4 44.8a15.9 15.9 0 0 0 19.2 0L192 464l54.4 44.8a15.9 15.9 0 0 0 19.2 0L320 464l38.4 44.8c10.5 7.9 25.6.4 25.6-12.8V16c0-13.2-15-20.7-25.6-12.8zM320 360c0 4.4-3.6 8-8 8H72c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h240c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H72c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h240c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H72c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h240c4.4 0 8 3.6 8 8v16z",
        },
        {
            title: t("Local Hotel Inventory"),
            description: t(
                "Direct contracted hotels",
            ),
            viewBox: "-30 0 650 700",
            icon:
                "M436 480h-20V24c0-13.255-10.745-24-24-24H56C42.745 0 32 10.745 32 24v456H12c-6.627 0-12 5.373-12 12v20h448v-20c0-6.627-5.373-12-12-12zM128 76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76zm0 96c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40zm52 148h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12zm76 160h-64v-84c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v84zm64-172c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40z",
        },
    ];

    const getInTouchArray: string[] = [
        t("Do your employees find business travel reservation and expense management too complicated and frustrating?"),
        t("Is there too much manual work around business travels or is the business travel growth not sustainable?"),
        t("It is not possible to create a comprehensive and standardised report about all business travel expenses?"),
    ];

    const data = useStaticQuery(graphql`
		query BusinessTravelManagementPageQuery {
			allFile(filter: { relativePath: { in: ["banner/product_btm.jpg"] } }) {
				edges {
					node {
						relativePath
						childImageSharp {
							fluid(quality: 100, maxWidth: 1920) {
								...GatsbyImageSharpFluid
								...GatsbyImageSharpFluidLimitPresentationSize
							}
						}
					}
				}
			}
		}
	`);

    const fluidBanner = data.allFile.edges[0].node.childImageSharp.fluid;

    return (
        <Layout>
            <SEO title="Products" />
            <div className="text-brand-grayt">
                {/* Banner */}
                <BackgroundImage Tag="div" fluid={fluidBanner}>
                    <div className="container text-black text-white h-400px sm:pt-16">
                        <div className="md:w-4/5 lg:w-2/3 xl:w-1/2 px-4 highlight">
                            <h1 className="pt-12 text-3xl sm:text-4xl leading-tight font-bold">
                                <div>
                                    <Trans>DEEP</Trans>
                                </div>
                                <span>
                                    <Trans>Business Travel Management</Trans>
                                </span>
                            </h1>
                            <p className="text-lg mt-5 pb-24">
                                <span>
                                    <Trans i18nKey="We believe in ready-to-use software products">
                                        We believe in ready-to-use software products combined with enterprise grade
                                        custom development service. That’s why we are keen to build convenient software
                                        solutions for our airline, tour operator, OTA partners.
									</Trans>
                                </span>
                            </p>
                        </div>
                    </div>
                </BackgroundImage>
                {/* Intro */}
                <div id="intro">
                    <div className="container p-4 pt-8">
                        <h1 className="font-bold text-2xl pb-6">
                            <Trans>A software Designed to Simplify Business Travel and Save Cost</Trans>
                        </h1>
                        <p className="text-lg pb-4">
                            <Trans i18nKey="DEEP Business Travel Management solution">
                                DEEP Business Travel Management solution is designed to simplify the process of a
                                business travel starting from planning until all expenses are accounted. The booking
                                platform has a built-in policy engine that seamlessly integrates with expense reporting
                                tools.
							</Trans>
                        </p>

                        <p className="text-lg pb-4">
                            <Trans i18nKey="It is based on DEEP Travel Reservation system">
                                It is based on DEEP Travel Reservation System but extended with special solutions to
                                cover the business travel related intercompany needs. There is a intuitive mobile
                                application to manage business travels while on the go, even without internet access.
                                Back office travel administrators can monitor booking statuses and handle change
                                requests
							</Trans>
                        </p>

                        <p className="text-lg p-4 mb-2 bg-brand-gray4">
                            <q>
                                <Trans i18nKey="Travel Policy and Approval">
                                    Travel Policy and Approval flow is easy to use and reduces the manual work to the
                                    least possible.
								</Trans>
                            </q>
                        </p>

                        <p className="text-lg pb-4">
                            <Trans i18nKey="Using the DEEP ecosystem">
                                Using the DEEP ecosystem allows you to upload your contracted corporate hotel rates and
                                make it bookable for employees without any hassle.
							</Trans>
                        </p>
                    </div>
                </div>
            </div>
            {/* Features */}
            <div id="features" className="bg-brand-gray4 pb-8">
                <div className="container p-4 pt-8">
                    <h1 className="uppercase font-bold text-2xl mb-10">
                        <span className="text-brand-green mr-2">//</span>
                        <Trans>Features</Trans>
                    </h1>
                    <div className="flex flex-wrap text-brand-grayt">
                        {FEATURES.map((feature) => {
                            return (
                                <div className="w-full sm:w-1/2 flex" key={feature.title}>
                                    <div className="mt-2 mr-2">
                                        <svg
                                            className="w-14 fill-current"
                                            viewBox={feature.viewBox}
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d={feature.icon} />
                                        </svg>
                                    </div>
                                    <div>
                                        <div className="font-semibold text-xl">{feature.title}</div>
                                        <div className="mb-4 mt-2 mr-4 text-lg">{feature.description}</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <GetInTouch getInTouchArray={getInTouchArray} />
            <RelatedProducts missing="deep-business-travel" />
        </Layout>
    );
};

export default BusinessTravelManagementPage;
