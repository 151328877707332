import React from "react";
import { Link } from "gatsby";
import { Trans } from "gatsby-plugin-react-i18next";

const GetInTouch = ({ getInTouchArray }: { getInTouchArray: string[] }) => {
    return (
        <div id="getInTouch" className="bg-brand-green text-white">
            <div className="container p-4 pt-6">
                <h1 className="mb-6 text-2xl font-bold">
                    <Trans i18nKey="Get in touch">
                        Get in Touch
                    </Trans>
                </h1>
                <ul className="list-outside list-disc pl-5">
                    {getInTouchArray.map((line) => (
                        <li key={line} className="text-lg mb-3">
                            {line}
                        </li>
                    ))}
                </ul>
                <p className="mt-5 mb-3 text-lg font-bold">
                    <Trans i18nKey="Schedule a discovery call">
                        Schedule a discovery call with us to find the perfect solution. We are at your service.
                    </Trans>
                </p>
                <div className="text-right mr-5">
                    <a href={`mailto:info@dgital.com`} className="hover:text-brand-gray4 inline-grid grid-cols-1">
                        <div className="text-xl font-semibold uppercase w-full">
                            <Trans i18nKey="Contact us">
                                Contact Us
                            </Trans>
                            &#10230;</div>
                        <div>
                            <svg
                                className="w-4 fill-current inline mr-1"
                                viewBox="0 0 580 580"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M462,54.955L355.371,437.187l-135.92-128.842L353.388,167l-179.53,124.074L50,260.973L462,54.955z M202.992,332.528v124.517l58.738-67.927L202.992,332.528z" />
                            </svg>
                            <section className="text-sm inline">info@dgital.com</section>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default GetInTouch;
