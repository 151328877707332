import React from "react";
import {} from "gatsby";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";

type ProductType = "ibe" | "deep-reservation-system" | "deep-travel-connector" | "deep-business-travel";

interface RelatedProduct {
	name: ProductType;
	category: string;
	title: string;
	slug: string;
	items: string[];
}

const RelatedProducts = ({ missing }: { missing: ProductType }) => {
	const { t } = useTranslation();

	const RELATED_PRODUCTS: RelatedProduct[] = [
		{
			name: "deep-reservation-system",
			category: t("Tour Operator & OTA"),
			title: t("DEEP Reservation System"),
			slug: "/products/travel-reservation-system",
			items: [
				t("Dynamic packaging"),
				t("Local inventory"),
				t("Web booking engine"),
				t("Agent distribution"),
				t("Instant payment solution"),
			],
		},
		{
			name: "deep-travel-connector",
			category: t("Aviation, Tour & OTA"),
			title: t("DEEP Travel Connector"),
			slug: "/products/travel-connector",
			items: [
				t("Third party products"),
				t("Booking-flow integrated"),
				t("Sell in email or in itinerary"),
				t("Backoffice website"),
				t("Advanced analytics"),
			],
		},
		{
			name: "ibe",
			category: t("Aviation"),
			title: t("Airline Booking Engine"),
			slug: "/products/airline-booking-engine",
			items: [
				t("Conversion optimized flow"),
				t("Bundle product sales"),
				t("Loyalty program support"),
				t("Flexible 3rd party ancillaries"),
				t("PCI DSS certified"),
			],
		},
		{
			name: "deep-business-travel",
			category: t("For Everybody"),
			title: t("DEEP Business Travel"),
			slug: "/products/business-travel-management",
			items: [
				t("Book package or standalone"),
				t("Custom travel policy"),
				t("Seamless platforms"),
				t("Contracted hotel rates"),
				t("Advanced reports"),
			],
		},
	];

	return (
		<div id="relatedProducts" className="bg-white">
			<div className="container p-4 pb-8">
				<h1 className="mb-4 text-2xl font-bold">
					<Trans>Related Products</Trans>
				</h1>
				<div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-2 lg:gap-12">
					{RELATED_PRODUCTS.filter((product) => product.name !== missing).map((product) => (
						<Link
							to={product.slug}
							key={product.name}
							className="w-full relative m-auto sm:h-290px shadow-xl p-4 xl:pl-10 border border-gray-100"
						>
							<div className="px-4">
								<div className="flex mb-2">
									<div>
										<div>{product.category}</div>
										<div className="border border-1 border-brand-green"></div>
									</div>
								</div>
								<div className="font-semibold mb-3 text-xl">{product.title}</div>
								<ul>
									{product.items.map((item) => (
										<li key={item} className="text-lg list-disc list-outside ml-4 mb-1">
											{item}
										</li>
									))}
								</ul>
								<div className="text-brand-green hover:text-brand-gray2 text-right block absolute inset-x-0 bottom-0 mb-4 mr-6">
									&#10230;
								</div>
							</div>
						</Link>
					))}
				</div>
			</div>
		</div>
	);
};

export default RelatedProducts;
